<template>
  <div>
    <div class="topButton">
      <div class="titleFont noButtonTitle floatLeft">Profile</div>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <ValidationObserver tag="form" ref="form">
          <CForm>
            <validation-provider
              rules="required"
              v-slot="{ errors }"
              name="姓名"
            >
              <div role="group" class="form-group">
                <label for="uid-y6g6u3sz7zn" class=""> 姓名 </label>
                <input
                  id="uid-y6g6u3sz7zn"
                  type="text"
                  placeholder="name"
                  class="form-control name"
                  v-model="get_profile.name"
                />
              </div>

              <div class="errMsg">
                <span>{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <ValidationProvider
              rules="min:12"
              v-slot="{ errors }"
              :bails="false"
              tag="div"
              name="password"
              mode="lazy"
            >
              <CInput
                label="變更密碼"
                placeholder="Password"
                type="password"
                autocomplete="new-password"
                v-model="password"
              >
              </CInput>
              <div class="errMsg" v-for="(error, index) in errors" :key="index">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider
              rules="confirmPassword:@password"
              v-slot="{ errors }"
              :bails="false"
              tag="div"
              mode="lazy"
              name="repeat password"
            >
              <CInput
                label="確認密碼"
                placeholder="Confirm password"
                type="password"
                autocomplete="new-password"
                class="mb-4"
                v-model="confirmPassword"
              >
              </CInput>
              <div class="errMsg" v-for="(error, index) in errors" :key="index">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </CForm>
        </ValidationObserver>
      </CCardBody>
      <div class="form-group form-actions positionR">
        <div class="sendButton">
          <div class="button floatRight"></div>
          <div class="button floatRight">
            <CButton
              block
              color="dark"
              variant="outline"
              @click="sendBtn()"
              v-if="userAuth.indexOf('edit-profile') >= 0"
              >確認
            </CButton>
          </div>

          <div class="clearBoth"></div>
        </div>
      </div>
    </CCard>

    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'CreateMember',

  data() {
    return {
      id: this.$route.params.id,
      role: 1,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      userAuth: sessionStorage.getItem('userAuth'),
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(['get_profile']),
  },
  methods: {
    sendBtn() {
      let data = {
        name: $('.name').val(),
        password: this.password,
        confirmPassword: this.confirmPassword,
      }

      this.$store.dispatch('actionUpdateProfile', data)
    },
  },
  created() {
    //   取個人資料
    this.$store.dispatch('actionGetProfile')
  },
}
</script>
